// WEB COMPONENT: <pbt-container-query>
var attributes = ['title', 'content', 'url', 'cta', 'img_default', 'img_sm', 'img_md', 'img_lg', 'img_xl', 'img_2xl', 'img_3xl', 'img_4xl', 'img_5xl', 'img_6xl', 'img_7xl', 'debug'];
class pbtContainerQuery extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        // set component attributes
        for (var i = 0; i < attributes.length; i++) {
            var attribute = attributes[i];
            if (this.hasAttribute(attribute)) {
                this[attribute] = this.getAttribute(attribute);
            }
        }
    }
    // component attributes
    static get observedAttributes() {
        return attributes;
    }
    // attribute change
    attributeChangedCallback(property, oldValue, newValue) {
        if (oldValue === newValue) return;
        this[property] = newValue;
        this.render();
    }
    // connect component
    connectedCallback() {
        // console.log('<pbt-container-query> connected');
    }
    render() {

        var title = this.title,
            title = title.replace('<em', '<em class="block lemonade text-8xl text-orange-2 -mt-4"'),
            title = title.replace('<span', '<span class="block display-mobile-medium @sm:display-mobile-large text-white last:-mt-4"'),
            content = this.content,
            url = this.url,
            cta = this.cta,
            img_default = this.img_default,
            img_sm = this.img_sm,
            img_md = this.img_md,
            img_lg = this.img_lg,
            img_xl = this.img_xl,
            img_2xl = this.img_2xl,
            img_3xl = this.img_3xl,
            img_4xl = this.img_4xl,
            img_5xl = this.img_5xl,
            img_6xl = this.img_6xl,
            img_7xl = this.img_7xl,
            debug = this.debug;

        this.shadowRoot.innerHTML = `
            <link rel="stylesheet" type="text/css" href="/pbt/styles/global.css">
            <link href="/pbt/styles/tailwind.css" rel="stylesheet">
            <style>
                .block-link-callout:hover .btn-gradient,
                .block-link-callout:focus .btn-gradient {
                    background-position: right;
                    -webkit-text-decoration-line: none;
                    text-decoration-line: none;
                    box-shadow: var(--shadow-lg);
                }
                .block-link-callout:hover img {
                    opacity: 0.9;
                    scale: 1.02;
                }
            </style>
            <div data-shadow-placeholder class="grid h-full">
                Loading...
            </div>
        `;
        var shadowPlaceholder = this.shadowRoot.querySelector('[data-shadow-placeholder]');
        // get width of shadow-size
        var width = shadowPlaceholder.getBoundingClientRect().width,
            resizeArguments = [{
                'function': processResize,
                'args'    : attributes
            }];
        // set width of shadow-width
        shadowPlaceholder.innerHTML = width;
        // resize function
        function processResize(resizeArguments) {
            var width = shadowPlaceholder.getBoundingClientRect().width,
                width = Math.round(width),
                breakpoint_label = ` default `,
                colspanClass = ` col-span-12 `,
                contentClasses = ` hidden `,
                textWrapperClasses = ``,
                buttonWrapperClasses = ``,
                shared_image_classes = ` absolute inset-0 z-0 object-cover bg-center w-full h-full duration-200 `,
                bgImage = `<img src="${img_default}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`,
                breakpoint = { 'sm': 383, 'md': 447, 'lg': 511, 'xl': 575, '2xl': 671, '3xl': 767, '4xl': 895, '5xl': 1023, '6xl': 1151,'7xl': 1279 };
            /*
            /  generate breakpoint variable for each breakpoint
            /  this is only used for debugging
            */
            for (var key in breakpoint) {
                if (breakpoint.hasOwnProperty(key)) {
                    if(width > breakpoint[key]) {
                        breakpoint_label = key;
                    }
                }
            }
            /*
            /  1. GENERATE `colspanClass` VARIABLE for each breakpoint.
            /     This is used to set the column span for the copy area
            /  2. GENERATE `bgImage` VARIABLE for each breakpoint.
            /     This is used to set the background image for the callouts.
            /     if the breakpoint is smaller than the image size, the
            /     image is not used.
            */
            if(width > breakpoint['sm']) {
                colspanClass = ` col-span-10 `
                contentClasses = ` block `
                textWrapperClasses = ` ml-6 px-0 `
                buttonWrapperClasses = ` mt-0 text-left ml-6 px-0 `
                if(img_sm) bgImage = `<img src="${img_sm}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            if(width > breakpoint['md']) {
                colspanClass = ` col-span-6 `
                if(img_md) bgImage = `<img src="${img_md}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            if(width > breakpoint['lg']) {
                colspanClass = ` col-span-7 `
                textWrapperClasses = ` ml-8 px-0 `
                buttonWrapperClasses = buttonWrapperClasses + ` mt-0 text-left ml-6 px-0 ml-8 `
                if(img_lg) bgImage = `<img src="${img_lg}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            if(width > breakpoint['xl']) {
                colspanClass = ` col-span-6 `
                if(img_xl) bgImage = `<img src="${img_xl}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            if(width > breakpoint['2xl']) {
                colspanClass = ` col-span-6 `
                if(img_2xl) bgImage = `<img src="${img_2xl}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            if(width > breakpoint['3xl']) {
                colspanClass = ` col-span-5 `
                if(img_3xl) bgImage = `<img src="${img_3xl}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            if(width > breakpoint['4xl']) {
                colspanClass = ` col-span-4 `
                if(img_4xl) bgImage = `<img src="${img_4xl}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            if(width > breakpoint['5xl']) {
                colspanClass = ` col-span-4 `
                if(img_5xl) bgImage = `<img src="${img_5xl}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            if(width > breakpoint['6xl']) {
                colspanClass = ` col-span-3 `
                if(img_6xl) bgImage = `<img src="${img_6xl}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            if(width > breakpoint['7xl']) {
                colspanClass = ` col-span-3 `
                if(img_7xl) bgImage = `<img src="${img_7xl}" alt="" class="absolute ${shared_image_classes}" loading="lazy">`
            }
            shadowPlaceholder.innerHTML = `
                <div class="grid col-span-12 lg:col-span-6 relative z-10">
                    ${debug === 'true' ? `
                        <div class="text-sm text-green-100 font-bold px-3 py-2 font-mono absolute z-50 bg-black rounded-lg left-2 top-2">${breakpoint_label}</div>
                    ` : ''}
                    <a class="block image-callout rounded-xl overflow-hidden z-10 relative blur-box active:ring-offset-2 active:ring-offset-white active:ring-purple-3 active:ring-2 hover:ring-offset-2 hover:ring-offset-white hover:ring-purple-2 hover:ring-2 bg-gray-transparent-1 duration-200 @container block-link-callout cursor-pointer" href="${url}" title="franchise opportunities">
                        ${bgImage}
                        <div class="grid grid-cols-12 relative z-10 ">
                            <div class="py-8 px-4 ${textWrapperClasses + colspanClass}">
                                <h3>${title}</h3>
                                <div class="text-white mt-4 [&>p]:body-large-reg-wt @4xl:[&>p]:heading-3 ${contentClasses}">
                                    ${content}
                                </div>
                            </div>
                        </div>
                        <div class="text-left relative z-40 py-8 px-4 ${buttonWrapperClasses}">
                            <span class="btn btn-gradient btn-theme">${cta}</span>
                        </div>
                    </a>
                </div>
            `;
        }
        // update on resize
        window.addEventListener('resize', function() {
            processResize(resizeArguments)
        });
        processResize(resizeArguments)
        var intervals = [0,100];
        intervals.forEach(function(interval) {
            setTimeout(function() {
                processResize(resizeArguments)
            }, interval);
        });
    } // end render
} // end class
// register component
customElements.define('pbt-container-query', pbtContainerQuery);